exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-destinations-tsx": () => import("./../../../src/pages/destinations.tsx" /* webpackChunkName: "component---src-pages-destinations-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-posts-tsx": () => import("./../../../src/pages/posts.tsx" /* webpackChunkName: "component---src-pages-posts-tsx" */),
  "component---src-pages-search-tsx": () => import("./../../../src/pages/search.tsx" /* webpackChunkName: "component---src-pages-search-tsx" */),
  "component---src-templates-blog-post-tsx-content-file-path-builds-foly-studios-travels-content-blog-north-of-tenerife-part-2-index-md": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/builds/foly-studios/travels/content/blog/north-of-tenerife-part-2/index.md" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-builds-foly-studios-travels-content-blog-north-of-tenerife-part-2-index-md" */),
  "component---src-templates-blog-post-tsx-content-file-path-builds-foly-studios-travels-content-blog-north-of-tenerife-top-sights-itineraries-and-instagram-worthy-spots-index-md": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/builds/foly-studios/travels/content/blog/north-of-tenerife-top-sights-itineraries-and-instagram-worthy-spots/index.md" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-builds-foly-studios-travels-content-blog-north-of-tenerife-top-sights-itineraries-and-instagram-worthy-spots-index-md" */),
  "component---src-templates-blog-post-tsx-content-file-path-builds-foly-studios-travels-content-blog-unveiling-tenerife-my-journey-to-the-island-of-eternal-spring-index-md": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/builds/foly-studios/travels/content/blog/unveiling-tenerife-my-journey-to-the-island-of-eternal-spring/index.md" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-builds-foly-studios-travels-content-blog-unveiling-tenerife-my-journey-to-the-island-of-eternal-spring-index-md" */),
  "component---src-templates-destination-tsx": () => import("./../../../src/templates/destination.tsx" /* webpackChunkName: "component---src-templates-destination-tsx" */)
}

